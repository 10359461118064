import { useState, useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import { authActions } from "../../../actions/auth.action";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { homeActions } from "../../../actions/home.action";
import { history } from "../../../history";
import { ROUTES } from "../../../constants";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import { QrCodeDialog } from "./QrCodeDialog";
import { useParams } from "react-router";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(3),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  grow: {
    flexGrow: 1,
  },
  homeSelector: {
    margin: 0,
    "& .MuiFormControl-marginDense": {
      margin: 0,
    },
    "& .MuiAutocomplete-inputRoot": {
      padding: "0 !important",
    },
    "& input": {
      color: "#fff",
      fontWeight: 700,
      lineHeight: 0,
      paddingLeft: "0 !important",
    },
    "& fieldset": {
      border: 0,
    },
    "& .MuiIconButton-label": {
      display: "none",
      color: "#fff",
    },
  },
}));

export function TopBar(props) {
  const classes = useStyles();
  const { handleDrawerToggle, open } = props;
  const [homes, setHomes] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();
  const home = useSelector((state) => state.home);
  const [defaultHome, setDefaultHome] = useState(home?.home || {});
  let { id } = useParams();
  const isHomeManagementPage =
    history.location.pathname === ROUTES.HOME_MANAGEMENT ||
    history.location.pathname === ROUTES.DOCUMENT_LISTING ||
    history.location.pathname === ROUTES.DOCUMENT_MANAGEMENT ||
    history.location.pathname === ROUTES.ROLE_MANAGEMENT;
  const [qrCodeEntity, setQrCodeEntity] = useState(false);

  useEffect(() => {
    if (!isHomeManagementPage) {
      axios.get("/api/homes/references").then((response) => {
        let data = response.data || [];
        data = data.content || [];

        setHomes(data);

        const found = data.find((item) => item.id == home?.home?.id) || data[0];

        if (found) {
          setDefaultHome(found);
          dispatch(homeActions.set(found));
        }
      });
    }
  }, []);

  const handleDefaultHomeChange = (event, values) => {
    if (values.id) {
      setDefaultHome(values);
      dispatch(homeActions.set(values));
    }
  };

  const handleLogout = (event) => {
    authActions.logout();
    window.location.href = ROUTES.LOGIN;
  };

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          edge="start"
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        {!isHomeManagementPage && homes ? (
          <Autocomplete
            disabled={history.location.pathname === `/residents/${id}`}
            noOptionsText={"沒有選項"}
            label="院舍名稱"
            className={classes.homeSelector}
            options={homes}
            getOptionLabel={(option) => option.nameTc}
            getOptionSelected={(option) => option.id === defaultHome.id}
            value={defaultHome}
            onChange={handleDefaultHomeChange}
            style={{ width: 250 }}
            disableClearable={true}
            renderInput={(params) => (
              <TextField
                {...params}
                readOnly
                label={false}
                variant="outlined"
                margin="dense"
              />
            )}
            renderOption={(option) => (
              <Typography noWrap>{option.nameTc}</Typography>
            )}
          />
        ) : (
          <Typography variant="h6" noWrap>
            Elderly Care Home
          </Typography>
        )}
        <div className={classes.grow} />
        {/* <div style={{ marginRight: 15 }}>
          {!isHomeManagementPage && homes && (
            <Tooltip title="掃描QR Code">
              <IconButton
                edge="end"
                aria-haspopup="true"
                color="inherit"
                onClick={() => {
                  setQrCodeEntity(true);
                }}
              >
                <CameraAltIcon />
              </IconButton>
            </Tooltip>
          )}
        </div> */}

        <div>
          <Tooltip title="登出">
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              color="inherit"
              onClick={handleLogout}
            >
              <AccountCircle />
            </IconButton>
          </Tooltip>
        </div>
      </Toolbar>
      <QrCodeDialog
        open={qrCodeEntity}
        onClose={() => {
          setQrCodeEntity(null);
        }}
      />
    </AppBar>
  );
}
